// Uncomment to load Bootstrap plugins as needed

import Collapse from "bootstrap/js/dist/collapse";
// import Alert from "bootstrap/js/dist/alert";
// import BaseComponent from "bootstrap/js/dist/base-component";
// import Button from "bootstrap/js/dist/button";
// import Carousel from "bootstrap/js/dist/carousel";
// import Dropdown from "bootstrap/js/dist/dropdown";
// import Modal from "bootstrap/js/dist/modal";
// import Offcanvas from "bootstrap/js/dist/offcanvas";
// import Popover from "bootstrap/js/dist/popover";
// import Scrollspy from "bootstrap/js/dist/scrollspy";
// import Tab from "bootstrap/js/dist/tab";
// import Toast from "bootstrap/js/dist/toast";
// import Tooltip from "bootstrap/js/dist/tooltip";
